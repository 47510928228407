<div class="container-fluid">
    <h2 class="mb-3 h1">{{title}}</h2>
    <p>
    Le caveau du Dorfhüs ouvre ses portes les midis du lundi au vendredi
    </p>
    <p>
    Je vous propose à emporter chaque jour un plat, un snack, une soupe, une salade, des desserts...
    </p>
    <p>
    Afin de privilégier la qualité, la fraîcheur, je vous propose une cuisine du marché local qui évolue au fil des saisons.
    </p>
    <p>
    Je privilégie les circuits courts en mettant en valeur les producteurs et artisans locaux.
    </p>
    <p>
    Retrouvez chaque dimanche la carte de la semaine.
    </p>
</div> 
