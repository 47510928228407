<div class="container">
  <app-navbar [title]="websitetitle"></app-navbar>
  <section id="about" class="bg-image p-5 text-center shadow-1-strong rounded mb-5 fs-3">
    <app-about [title]="subtitle"></app-about> 
  </section>
  <section id="how" style="padding: 1rem">
    <app-how></app-how>
  </section>
  <section id="menu">
    <app-menu [title] = "menutitle" [days]="days" [isWeekOff]="isWeekOff" [message]="vacationNotice"></app-menu>
  </section>
</div>