<div class="container-fluid">
    <div class="row justify-content-md-center">
      <div class="col-md-auto">
        <h2>Plats du jour et snacking à emporter à Eschentzwiller</h2>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-auto fs-3">
        <p>
          Caveau du Dorfhüs
        </p>
        <p>
          4 rue des Tilleuls
        </p>
        <p>
          68440 <a href="https://www.eschentzwiller.fr/">Eschentzwiller</a>
        </p>
      </div>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-auto">
        <h2>
          Du lundi au vendredi de 11h30 à 13h30
        </h2>
      </div>
    </div>
    <!--
    <div class="row justify-content-md-center">
      <div class="col-md-auto">
        <h2>
          Réservations au 06 16 50 11 45 (au plus tard la veille)
        </h2>
      </div>
    </div>
    -->
  </div>
