import { Component, Input, OnInit } from '@angular/core';
import { Day } from '../day';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Input() title: string;
  @Input() days: Day[];
  @Input() isWeekOff: boolean;
  @Input() message: string;

  constructor() { }

  ngOnInit(): void {
  }

}
