import { Component } from '@angular/core';
import { Dish } from './dish';
import { Day } from './day';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  subtitle = 'Le caveau du Dorfhüs';
  websitetitle = "Calybeje";
  isWeekOff = true;
  vacationNotice = "";
  menutitle = "Menu du 10 au 14 Avril 2023";
  days: Day[] = [
    { label: 'Lundi',
      isOpen: false,
      message: 'Férié',
      dailySpecial: { title: 'Plat du jour', description: 'Endives au jambon gratinées', price: 10 }
    },
    { label: 'Mardi',
      isOpen: true,
      message: 'Fermeture exceptionnelle',
      dailySpecial: { title: 'Plat du jour', description: 'Spaghettis carbonara', price: 10 }
    },
    { label: 'Mercredi',
      isOpen: true,
      message: '',
      dailySpecial: { title: 'Plat du jour', description: 'Crevettes sautées à l\'ail, riz cantonnais', price: 11 },
    },
    { label: 'Jeudi',
      isOpen: true,
      message: 'Fermeture exceptionnelle',
      dailySpecial: { title: 'Plat du jour', description: 'Pot au feu ', price: 11 }
    },
    { label: 'Vendredi',
      isOpen: false,
      message: 'Fermeture exceptionnelle',
      dailySpecial: { title: 'Plat du jour', description: 'Lasagnes saumon épinards, salade', price: 11 }
    }
  ];
}
