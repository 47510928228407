<div class="container-fluid">
  <div *ngIf="!isWeekOff; else elseBlock">
    <div class="row justify-content-md-center">
      <div class="col-md-auto">
        <h1>
          {{title}}
        </h1>
      </div>
    </div>
    <div *ngFor="let day of days">
      <div class="row gx-4 border" style="margin-top: 5px; margin-bottom: 5px" >
        <h1 style="text-align: center; color: white">{{day.label}}</h1>
        <div *ngIf="!day.isOpen" style="text-align: center; color: white">
          <h2>{{day.message}}</h2>
        </div>
        <div class="col-sm gx-5" [hidden]="!day.isOpen">
          <app-dish-day [dish]="day.dailySpecial"></app-dish-day>
        </div>
        <div class="col-sm gx-5" *ngIf="day.snacking" [hidden]="!day.isOpen">
          <app-dish-day [dish]="day.snacking"></app-dish-day>
        </div>
        <div class="col-sm gx-5" *ngIf="day.dessert" [hidden]="!day.isOpen">
          <app-dish-day [dish]="day.dessert"></app-dish-day>
        </div>
      </div>
    </div>
  </div>
  <ng-template #elseBlock>
    <h1>{{message}}</h1>  
  </ng-template>
</div>
