<div class="card text-dark bg-warning mb-3">
    <div class="card-header">
        {{dish.title}}
    </div>
    <div class="card-body">
        <p class="card-text">{{dish.description}}
        </p>
    </div>
    <div class="card-footer">
        {{dish.price}} €
    </div>
</div>
