<nav class="navbar navbar-expand-md bg-dark navbar-dark">
    <div class="container-fluid">
      <a class="navbar-brand mb-0 h1 fs-2 fw-bolder" href="#">
        <img src="./assets/images/logo.png" width="50" height="50"class="d-inline-block align-center" alt="">
        {{title}}
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#menu-nav">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="menu-nav" style>
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a href="#about" class="nav-link">A propos</a>
          </li>
          <li class="nav-item">
            <a href="#how" class="nav-link">Comment</a>
          </li>
          <li class="nav-item">
            <a href="#menu" class="nav-link">Menus</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
