import { Component, Input, OnInit } from '@angular/core';
import { Dish } from '../dish';

@Component({
  selector: 'app-dish-day',
  templateUrl: './dish-day.component.html',
  styleUrls: ['./dish-day.component.scss']
})
export class DishDayComponent implements OnInit {
  @Input() dish: Dish = {
    title: '',
    description: '',
    price: 0
  }
  constructor() { }

  ngOnInit(): void {
  }

}
